<template>
  <div data-vue-name="Invites" class="invites invites--disabled" ref="inviteRef" @click="goToEvent()">
    <div class="invites__avatar">
      <a-avatar :size="32" :src="initiator.photo">
        <a-icon slot="icon" type="user" />
      </a-avatar>
    </div>

    <div class="invites__box-ofer">
      <div>{{ initiator.name }}</div>
      <div class="invites__status" :class="[statusInvite]">
        <template v-if="statusInvite !== 'Waiting'">
          {{ statusInvite }}
        </template>
        <template v-else>
          Offered you a competition
        </template>

      </div>
      <div>
        <svg-icon v-if="dataOpponent.competition.kind_sport.name === 'Swimming'" size="size16x16"
          class-name="ico-swimming me-1" name="ico-swimming" />
        <svg-icon v-if="dataOpponent.competition.kind_sport.name === 'Basketball'" size="size16x16"
          class-name="ico-basketball me-1" name="ico-basketball" />
        {{ dataOpponent.competition.kind_sport.name }}
      </div>
    </div>
    <div class="invites__date">{{ initiateDate }}</div>
    <button class="invites__btn-arrow-right">
      <svg-icon name="icoArrowRight" size="size20x20" />
    </button>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'Invites',
  emits: ['hasActive'],
  props: {
    dataOpponent: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.$emit['updateNotif']
    this.isExpire()
  },
  data() {
    return {
      invites: '',
      competitionVal: {},
      counter: 0
    }
  },
  computed: {
    initiator() {
      return this.dataOpponent.initiator
    },
    initiateDate() {
      const date = this.dataOpponent.invited_at
      if (!date) {
        return
      }

      return moment(date).format('DD MMM YYYY')

    },
    eventData() {
      const date = this.dataOpponent?.competition.event_at

      if (!date) {
        return
      }

      return moment(date).format('DD MMM YYYY')
    },
    statusInvite() {
      return this.dataOpponent.status
    },
    isInactive() {
      return (this.dataOpponent.competition.status === 'performances-are-marked-up'
        || this.dataOpponent.competition.status === 'judging-is-done'
        || this.dataOpponent.competition.status === 'video-post-prod-is-done'
        || this.dataOpponent.competition.status === 'video-is-scheduled'
        || this.dataOpponent.competition.status === 'video-is-live') && this.dataOpponent.status === 'Waiting'
    },
    getEventType() {
      if (!this.dataOpponent.competition.event_type) {

        return { name: this.dataOpponent.competition.status === 'video-is-live' ? 'verdict' : 'details', params: { id: this.dataOpponent.competition.id } }
      }
      if (this.dataOpponent.competition.event_type === 'App\\Models\\Event') {
        return { name: 'details-event', params: { id: this.dataOpponent.competition.event_id } }
      }
      if (this.dataOpponent.competition.event_type === 'App\\Models\\TournamentEvent') {
        return {
          name: 'details-tournament-event', params: { id: this.dataOpponent.competition.event_id }
        }
      }
      return '/'
    },
    isEventRegClose() {
      return this.dataOpponent.competition.event_type === 'App\\Models\\TournamentEvent' && this.dataOpponent.competition.status === 'registration-closed'
    }
  },
  methods: {
    goChatUserInvite() {
      this.$router.push({
        name: 'chat-user-invite',
        params: { chatUserData: this.dataOpponent },
      })
    },
    isExpire() {
      if (this.isEventRegClose || this.dataOpponent.competition.challenge_status === 'overdue' || this.dataOpponent.competition.challenge_status === 'accepted' || this.statusInvite !== 'Waiting' || this.isInactive) {
        this.$refs['inviteRef'].classList.value = 'invites invites--disabled'
      } else if (this.statusInvite === 'Waiting' && !this.isInactive && this.dataOpponent.competition.challenge_status !== 'overdue') {
        this.$refs['inviteRef'].classList.value = 'invites'
        this.$emit('hasActive')
      } else {
        this.$refs['inviteRef'].classList.value = 'invites'
      }
    },
    goToEvent() {
      const type = this.getEventType
      this.$emit['updateNotif']
      console.log(type);
      this.$router.push(type)
    }
  },
}
</script>

<style lang="stylus">
  .invites {
    display flex
    margin-bottom 24px
  }
  .invites--disabled {
    opacity .5
  }
  .invites__avatar {
    width 32px
    height 32px
    margin-right 8px
    overflow hidden
    border-radius 50%
    flex-shrink 0
  }
  .invites__avatar img {
    width 100%
    height 100%
    object-fit cover
  }
  .invites__box-ofer {
    flex-grow 1
    color #D8D8D8
  }
  .invites__status {
    font-size 12px
    font-weight: 500;
    color: #7B8B97;
    margin-bottom: 3px

    &.rejected {
      color: #D21341;
    }
    &.accepted {
      color: #4285F4;
    }
  }
  .invites__date {
    align-self: center;
    font-size 12px
    color: #7B8B97;
  }
  .invites__btn-arrow-right {
    margin-left 8px
    display flex
    align-items center
    justify-content center
  }
</style>
