<template>
  <view-container name="notifications">
    <view-header>
      <header-competitions class="view-header">Notifications</header-competitions>
    </view-header>

    <view-content>
      <div class="notifications">
        <a-tabs type="card" @tabClick="tabClick">
          <a-tab-pane key="invites">
            <div slot="tab" class="title title-notif">
              <span ref="tabInvites">{{ $t('pages.competitions.invites') }}</span>
              <a-badge v-if="hasActiveCompetition" color="#4285F4" />
            </div>

            <!-- <Invites v-for="opponent in basketballInvites" :key="`op2_${opponent.id}`" :data-opponent="opponent"
              :competition="getCompetitionProm(opponent.competition.id)" @has-active="hasActiveCompetition = true" /> -->
            <Invites v-for="opponent in invites" :key="`op_${opponent.id}`" :data-opponent="opponent"
              @hasActive="hasActiveCompetition = true" @updateNotif="initIndicators()" />
          </a-tab-pane>
          <a-tab-pane key="notifications">
            <div slot="tab" class="title title-notif">
              <span ref="tabNotifications">{{ $t('notifications.notifications') }}</span>
              <a-badge v-if="hasNotifications" color="#4285F4" />
            </div>

            <Notification v-for="notification in notifications" :key="notification.id" :notification="notification" />
          </a-tab-pane>



          <!-- <a-tab-pane key="invites">
            <div slot="tab" class="title">
              <span ref="tabInvites">{{ $t('pages.competitions.invites') }}</span>
              <a-badge v-if="hasActiveCompetition" color="#4285F4" />
            </div>

            <Invites v-for="opponent in basketballInvites" :key="`op2_${opponent.id}`" :data-opponent="opponent"
              :competition="getCompetitionProm(opponent.competition.id)" @has-active="hasActiveCompetition = true" />
          <Invites v-for="opponent in swimmingInvites" :key="`op_${opponent.id}`" :data-opponent="opponent"
            @hasActive="hasActiveCompetition = true" />
          </a-tab-pane> -->
        </a-tabs>
      </div>
    </view-content>

    <view-footer>
      <footer-competitions class="view-footer" />
    </view-footer>
  </view-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Notification from '@/components/Notification'
import Invites from '@/components/Competitions/Invites'

export default {
  name: 'Notifications',
  components: {
    Notification,
    Invites,
  },
  data() {
    return {
      hasActiveCompetition: false,
    }
  },
  // async сreate() {
  //   // await this.$store.dispatch('competitions/getInvites')
  //   // await this.$store.dispatch('notifications/getNotifications')
  // },
  mounted() {
    // console.log('TEST', this.$route.fullPath);
    this.initIndicators();
    this.$store.dispatch('notifications/getNotifications')
  },
  methods: {
    initIndicators() {
      let target = this.$route.fullPath.split('#')
      this.$refs['tabInvites'].click()
      if (target[1]) {
        const capitalized =
          target[1].charAt(0).toUpperCase()
          + target[1].slice(1)
        this.$refs[`tab${capitalized}`].click()
      }
    },
    tabClick(value) {
      this.tabKey = value
      const routeWithHash = `${this.$route.path}#${value}`
      if (this.$route.fullPath !== routeWithHash) {
        this.$router.push(routeWithHash)
      }
    },
  },
  computed: {
    ...mapGetters({
      selectedSport: 'home/selectedSport'
    }),
    ...mapState('notifications', ['notifications']),
    ...mapState('competitions', {
      invites: ({
        invites
      }) => invites
    }),
    hasNotifications() {
      return this.notifications.find(notification => !notification.read_at)
    },
    basketballInvites() {
      return this.invites.filter(el => (el.competition?.kind_sport.name === "Basketball")).sort((a, b) => {
        if (a.status !== 'Waiting' && b.status === 'Waiting') return 1
        if (a.status === 'Waiting' && b.status !== 'Waiting') return -1
        return 0
      });
    },
    sortedInvites() {
      return [...this.invites].sort((a, b) => {
        if (a.status !== 'Waiting' && b.status === 'Waiting') return 1
        if (a.status === 'Waiting' && b.status !== 'Waiting') return -1
        return 0
      })
    },
    swimmingInvites() {
      return this.invites.filter(el => (el.competition && el.competition?.kind_sport.name === "Swimming")).sort((a, b) => {
        if (a.status !== 'Waiting' && b.status === 'Waiting') return 1
        if (a.status === 'Waiting' && b.status !== 'Waiting') return -1
        return 0
      });
    },
    activeTabName() {
      switch (this.tabKey) {
        case 'notification':
          return this.$t('pages.competitions.pending');
        case 'invites':
          return this.$t('pages.competitions.invites');
        default:
          return ''
      }
    },
    // activeBasketballInvites() {
    //   return this.basketballInvites.filter(el => (el.status === 'Waiting')).length > 0
    // },
  },
  async swipeUpdate() {
    await this.$store.dispatch('notifications/getNotifications')
    await this.$store.dispatch('competitions/getInvites')
  }
}
</script>
<style lang="stylus">
.title-notif {
  span:first-child {
    padding-right: 5px;
  }
}

.ant-tabs-tab {
  width: 125px !important;
}
</style>
