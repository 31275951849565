<template>
  <div class="notification-component notification" :class="classes" @click="click">
    <div class="notification__avatar">
      <a-avatar :size="32">
      </a-avatar>
    </div>
    <div class="left notification__box-ofer">
      <h1 class="title">{{ title }}</h1>
      <p class="description" v-if="description">{{ description }}</p>
    </div>
    <div class="right">
      <span class="date" style="margin-right: 25px;">{{ date }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'Notification',
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    classes() {
      const { read } = this

      return { read }
    },
    id() {
      return this.notification.id
    },
    date() {
      return moment(this.notification.created_at).format('ll')
    },
    data() {
      return this.notification.data
    },
    type() {
      return this.notification.type
    },
    read() {
      return !!this.notification.read_at
    },
    title() {
      const { type, data } = this

      switch (type) {
        case 'App\\Notifications\\CompetitionCanceled':
        case 'App\\Notifications\\CompetitionIsLive':
        case 'App\\Notifications\\ViewerInvited':
          return data.initiator || null
        case 'App\\Notifications\\Disqualification':
          return this.$t('notifications.disqualification')
        default:
          return type
      }
    },
    description() {
      const { type } = this

      switch (type) {
        case 'App\\Notifications\\CompetitionCanceled':
          return this.$t('notifications.competitioncanceled')
        case 'App\\Notifications\\CompetitionIsLive':
          return this.$t('notifications.competitionislive')
        case 'App\\Notifications\\Disqualification':
          return this.$t('notifications.disqualificationText')
        case 'App\\Notifications\\ViewerInvited':
          return this.$t('notifications.viewerinvited')
        default:
          return null
      }
    }
  },
  methods: {
    click() {
      const { type, data } = this

      this.$store.dispatch('notifications/read', this.id)

      switch (type) {
        case 'App\\Notifications\\Disqualification':
        case 'App\\Notifications\\CompetitionIsLive':
          if (!data.competition) {
            return
          }
          if (data.event_id && data.event_type === 'App\\Models\\Event') {
            this.$router.push({ name: 'streamed-event', params: { id: data.event_id } })
          } else if (data.event_id && data.event_type === 'App\\Models\\TournamentEvent') {
            this.$router.push({ name: 'streamed-tournament-event', params: { id: data.event_id } })
          } else {
            this.$router.push({ name: 'verdict', params: { id: data.competition } })
          }


          break
        case 'App\\Notifications\\CompetitionCanceled':
        case 'App\\Notifications\\ViewerInvited':
          if (!data.competition) {
            return
          }
          if (data.event_id && data.event_type === 'App\\Models\\Event') {
            this.$router.push({ name: 'details-event', params: { id: data.event_id } })
          } else if (data.event_id && data.event_type === 'App\\Models\\TournamentEvent') {
            this.$router.push({ name: 'details-tournament-event', params: { id: data.event_id } })
          } else {
            this.$router.push({ name: 'details', params: { id: data.competition } })
          }


          break
        default:
          return
      }
    }
  }
}
</script>

<style lang="stylus">
.notification {
  display flex
  margin-bottom 24px
}
.notification__avatar {
  width 32px
  height 32px
  margin-right 8px
  overflow hidden
  border-radius 50%
  flex-shrink 0
}
.notification__avatar img {
  width 100%
  height 100%
  object-fit cover
}
.notification__box-ofer {
  flex-grow 1
  color #D8D8D8
}

</style>
